@use 'styles/base/mixins';

.form {
  position: relative;
  margin-bottom: 24px;
}

.input {
  display: flex;
  width: 100%;
  height: 48px;
  padding: 0 var(--spacing-12);
  border: none;
  border-radius: 400px;
  appearance: none;

  @include mixins.text-small;

  &::-webkit-search-cancel-button {
    appearance: none;
  }

  &::placeholder {
    color: var(--black);
  }
}

.icon {
  position: absolute;
  top: 14px;
  left: 20px;
  width: 20px;
  height: 20px;
  color: var(--black);
  pointer-events: none;
}

.navItem {
  margin-bottom: var(--spacing-03);
}
